import { Box, List, ListItem, ListItemText, Link, ListItemAvatar, Avatar } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const EventDetails = ({ singleDoc }) => {

    return ( 
        <div className="event-details">
            <h4>Event details</h4>
            {singleDoc && <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                <Box>
                    <List dense={true} disablePadding={true}>
                        {"event_name" in singleDoc && <ListItem>
                            {"event_logo_url" in singleDoc &&
                                <ListItemAvatar>
                                    <Avatar alt="Event Avatar" src={singleDoc.event_logo_url} />
                                </ListItemAvatar>
                            }
                            <ListItemText
                                primary="Event Name"
                                secondary={singleDoc.event_name}
                            />
                        </ListItem>}
                        {"event_date" in singleDoc && <ListItem>
                            {"event_logo_url" in singleDoc &&
                                <ListItemAvatar>
                                    <Avatar><CalendarMonthIcon /></Avatar>
                                </ListItemAvatar>
                            }
                            <ListItemText
                                primary="Event Date"
                                secondary={singleDoc.event_date.toDate().toDateString()}
                            />
                        </ListItem>}
                    </List>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <List dense={true} disablePadding={true}>
                        {"event_organiser_name" in singleDoc && <ListItem>
                            <ListItemText
                                primary="Event Organiser"
                                secondary={"event_organiser_url" in singleDoc ?
                                    <Link href={singleDoc.event_organiser_url} target="_blank">{singleDoc.event_organiser_name}</Link> :
                                    singleDoc.event_organiser_name
                                }
                            />
                        </ListItem>}
                        {"event_results_url" in singleDoc && <ListItem>
                            <ListItemText
                                primary="Results"
                                secondary={<Link href={singleDoc.event_results_url} target="_blank">Results Link</Link>}
                            />
                        </ListItem>}
                    </List>
                </Box>
            </Box>}

        </div>
     );
}
 
export default EventDetails;