import { Paper, Button, Box, TextField, Typography, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useState, useContext } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection } from 'firebase/firestore';

import { galleryAuth, galleryFirestore } from '../firebase/config';
import { AuthContext } from '../contexts/AuthContext';

const SignUpForm = () => {

    const [emailError, setEmailError] = useState(false);
    const [passError, setPassError] = useState(false);
    const [passErrorMessage, setPassErrorMessage] = useState('');
    const [passMain, setPassMain] = useState(null);
    const [passConf, setPassConf] = useState(null);
    const [authError, setAuthError] = useState('');
    const [isUserCreated, setIsUserCreated] = useState(false);

    const { userId } = useContext(AuthContext);

    const validateEmail = (evt) => {
        evt.preventDefault();
        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(evt.target.value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    const validatePass = (evt) => {
        evt.preventDefault();

        if (evt.target.value.length < 8) {
            setPassError(true)
            setPassErrorMessage('Password must be at least 8 characters')
        } else {
            setPassError(false)
            setPassErrorMessage('')
            setPassMain(evt.target.value)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // console.log({
        //     firstName: data.get('firstName'),
        //     lastName: data.get('lastName'),
        //     email: data.get('email'),
        //     password: data.get('password'),
        //     confirmepassword: data.get('confirmepassword'),
        //     allowExtraEmails: data.get('allowExtraEmails'),
        // });
        createUserWithEmailAndPassword(galleryAuth, data.get('email'), data.get('password'))
            .then((cred) => {
                setAuthError('')
                // console.log(cred)
                setDoc(doc(collection(galleryFirestore, 'users'), cred.user.uid), {
                    firstname: data.get('firstName'),
                    lastname: data.get('lastName'),
                    allowExtraEmails: Boolean(data.get('allowExtraEmails')),
                }).then(() => { setIsUserCreated(true) })
            })
            .catch((err) => {
                setAuthError(err.message.replace('Firebase: ', ''))
            })

    };

    return (
        <Box sx={{
            display: "block",
            maxWidth: 500,
            marginX: "auto",
            py: 5

        }}>
            <Paper>
                {(!isUserCreated) && (!userId) && <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, px: "10%" }}>
                    <Box>
                        <Typography variant='h6' textAlign="center">Create Your Account</Typography>
                        <Typography textAlign="center">Please fill all fields to continue</Typography>
                    </Box>
                    <Grid container spacing={2} pt={5}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={emailError}
                                helperText={emailError && "Invalid email format"}
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onBlur={validateEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={passError}
                                helperText={passError && passErrorMessage}
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                onChange={validatePass}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={(passMain !== passConf)}
                                helperText={(passMain !== passConf) && "Passwords do not match"}
                                required
                                fullWidth
                                name="confirmepassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmepassword"
                                autoComplete="new-password"
                                onChange={(evt) => { setPassConf(evt.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" name="allowExtraEmails" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>
                    </Grid>
                    <Button
                        disabled={emailError || passError || (passMain !== passConf)}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    {authError && <Typography color="error">{authError}</Typography>}
                </Box>}
                {isUserCreated && <Box sx={{ py: 5, px: "10%" }}>
                    <Typography variant='h5' textAlign="center" sx={{ mb: 3 }}>Thank you for registering</Typography>
                    <Typography color='success' textAlign="center">Your user account has been succefully created</Typography>
                </Box>}
                {userId && <Typography color='success' textAlign="center">Your already have an account with us</Typography>}
            </Paper >
        </Box >
    );
}

export default SignUpForm;