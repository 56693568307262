
import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem, Badge, Dialog, Avatar } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';

import SignInForm from './SignInForm';
import { AuthContext } from '../contexts/AuthContext';


const NavBar = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [openSignInForm, setOpenSignInForm] = useState(false);

    const { isLoggedIn, userObj, signOutAuth } = useContext(AuthContext)

    const menuId = 'primary-search-account-menu';

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const hadleSignOut = () => {
        handleMenuClose();
        signOutAuth();
        navigate('/');
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {!isLoggedIn && <MenuItem onClick={() => {
                handleMenuClose();
                setOpenSignInForm(true);
            }}>Login</MenuItem>}
            {!isLoggedIn && <MenuItem onClick={() => {
                navigate("/signup");
                handleMenuClose();
            }}>Sign up</MenuItem>}
            {isLoggedIn && <MenuItem onClick={handleMenuClose}>Profile</MenuItem>}
            {isLoggedIn && <MenuItem onClick={() => {
                navigate("/account");
                handleMenuClose();
            }}>My account</MenuItem>}
            {isLoggedIn && <MenuItem onClick={hadleSignOut}>Logout</MenuItem>}
        </Menu>
    );

    const renderSignIn = (
        <Dialog
            open={openSignInForm}
            onClose={() => setOpenSignInForm(false)}
        >
            <SignInForm setOpenSignInForm={setOpenSignInForm} />
        </Dialog>
    );


    return (
        <Box>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                }}
                            >
                                <MenuItem key="All events" onClick={() => {
                                    navigate("/");
                                    setAnchorElNav(null);
                                }}>
                                    <Typography textAlign="center">All events</Typography>
                                </MenuItem>
                                <MenuItem key="Upcoming Events" onClick={() => {
                                    navigate("/");
                                    setAnchorElNav(null);
                                }}>
                                    <Typography textAlign="center">Upcoming Events</Typography>
                                </MenuItem>
                            </Menu>

                        </Box>
                        <Box
                            component="img"
                            sx={{ height: 24 }}
                            alt="Evpic logo."
                            src="/whitelogo192.png"
                        />
                        <Box sx={{ mr: 3, display: "flex", flexGrow: { xs: 1, sm: 0 } }}>
                            <Badge badgeContent={"beta"} color="error" >
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ mr: 3 }}
                                    onClick={() => navigate("/")}
                                >
                                    EVPIC
                                </Typography>
                            </Badge>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                            <Button
                                key="All events"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                onClick={() => navigate("/")}
                            >
                                All events
                            </Button>

                            <Button
                                key="Upcoming events"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Upcoming events
                            </Button>

                        </Box>

                        <Box sx={{ display: { xs: 'flex', sm: 'flex' } }}>
                            <IconButton size="large" aria-label="Cart" color="inherit">
                                <Badge badgeContent={0} color="error">
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                {(isLoggedIn && userObj && userObj.email) ? <Avatar sx={{ width: 24, height: 24 }}>{userObj.email.charAt(0).toUpperCase()}</Avatar> : <AccountCircle />}
                            </IconButton>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
            {renderMenu}
            {renderSignIn}
        </Box>
    );
}

export default NavBar;