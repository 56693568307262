import { useState, useEffect, useContext } from "react";
import { galleryStorage } from "../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; 
import { doc, setDoc, collection, Timestamp } from 'firebase/firestore';

import { galleryFirestore } from '../firebase/config';
import { AuthContext } from '../contexts/AuthContext';

const useStorageMult = (files, eventId, setFiles, isCancel) => {
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [fileIndex, setFileIndex] = useState(1);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);
    const [task, setTask] = useState(null);

    const { userId, userObjEx, userObj } = useContext(AuthContext);

    const storageRef = ref(galleryStorage);
    const eStorageRef = ref(storageRef, "uploads/" + eventId);
  
    useEffect(() => {

      if (files && (!isCancel)) {

        const file = files[fileIndex-1]
        setFileName(file.name);

        const fStorageRef = ref(eStorageRef, file.name);
        const uploadTask = uploadBytesResumable(fStorageRef, file); 

        setTask(uploadTask);

        uploadTask.on('state_changed', (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          setProgress(percentage);
        }, (err) => {
          setError(err);

          switch (err.code) {
            case 'storage/canceled':
              setFiles(null);
              break;
            case 'storage/unknown':
              setFiles(null);
              break;
          }
        }, () => {
          
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUrl(downloadURL);

            setDoc(doc(collection(doc(collection(galleryFirestore, "events"), eventId), "images")), {
              author: (userObj && ("displayName" in userObj) && userObj.displayName)? userObj.displayName: (userObjEx)? userObjEx.firstName + ' ' + userObjEx.lastName: "",
              user_id: userId,
              img_url: downloadURL,
              date_taken: Timestamp.fromMillis(file.lastModified),
            })

            if (fileIndex < files.length) {
              setFileIndex(fileIndex + 1);
              setProgress(0);
            } else {
              setFiles(null);
            }
          });
        });

      }
      },[files, eventId, fileIndex]);

    useEffect(() => {
      if (task && isCancel) {
        task.cancel();
      }
    }, [isCancel])

    return { progress, url, error, fileName, fileIndex };
  }
  
  export default useStorageMult;