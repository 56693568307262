import { useState } from "react";
import { Button, Box, Typography, LinearProgress } from "@mui/material";

import useStorageMult from "../hooks/useStorageMult";

const UploadPhotosProgress = ({fls, setFiles, eventId}) => {
  const [isCancel, setIsCancel] = useState(false);
  const { progress, url, fileName, fileIndex } = useStorageMult(fls, eventId, setFiles, isCancel);

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <LinearProgress
          sx={{ mb: 2 }}
          variant="determinate"
          value={progress}
        />
      </Box>
      {fls && (
        <Box display="flex" flexDirection="row" alignContent="center">
          <Typography sx={{ flexGrow: 1 }}>{fileName}</Typography>
          <Typography sx={{ ml: 1 }}>{fileIndex + " / " + fls.length}</Typography>
          <Button
            color="error"
            size="small"
            variant="outlined"
            onClick={() => setIsCancel(true)}
            sx={{ ml: 1 }}
          >
            Stop
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UploadPhotosProgress;
