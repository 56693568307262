import { Container, Box, Grid, Link, Typography } from "@mui/material";

const Footer = () => {
    return (
        <footer className="footer" >
            <Box bgcolor="#0a2941" color="#7A7A7A" pt={5}>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Typography>
                                    A New Way to Share Race Moments
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box borderBottom={1}>Activities</Box>
                            <Box display="flex" flexDirection="column">
                                <Link href='https://evpicz.com/athletes/' color="inherit" underline="hover" target="_blank">
                                    Athletes
                                </Link>
                                <Link href='https://evpicz.com/photographers/' color="inherit" underline="hover" target="_blank">
                                    Photographers
                                </Link>
                                <Link href='https://evpicz.com/race-directors/' color="inherit" underline="hover" target="_blank">
                                    Race directors
                                </Link>
                            </Box>
 
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box borderBottom={1}>Company</Box>
                            <Box display="flex" flexDirection="column">
                                <Link href='https://evpicz.com/about/' color="inherit" underline="hover" target="_blank">
                                    About
                                </Link>
                                <Link href='https://evpicz.com/privacy-policy/' color="inherit" underline="hover" target="_blank">
                                    Privacy Policy
                                </Link>
                                <Link href='https://evpicz.com/contact/' color="inherit" underline="hover" target="_blank">
                                    Contact
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Box textAlign="center" pt={{ xs: 5, sm: 10}} pb={{ xs: 5, sm: 0 }}>
                    Copyright © {new Date().getFullYear()} Evpicz, Evpic Ltd
                </Box>
            </Box>
        </footer>

     );
}
 
export default Footer;