import { Dialog, Box, Typography, MobileStepper, Button } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import SwipeableViews from 'react-swipeable-views';

import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FlickrBlock from "./FlickrBlock";
import FBBlock from "./FBBlock";

const ModalEx = ({ setSelectedItem, selectedItem, images }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(images.indexOf(selectedItem));
  const navigate = useNavigate();
  const location = useLocation();
  const locHash = useRef(0);
  const initialHash = useRef('');


  const handleClick = (e) => {
    setSelectedItem(null);
    (location.hash === "#dialog") && ((initialHash.current === '') ? navigate(-1) : navigate(location.pathname + location.search));
  }

  const maxSteps = images.length;
  const handleNext = () => {
    if (activeStep < (maxSteps-1)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSelectedItem(images[activeStep + 1]);
    }
  };

  const handleBack = () => {
    if (activeStep > 0){
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedItem(images[activeStep - 1]);
    }
  };

  useEffect(() => {
    initialHash.current = location.hash;
    navigate(location.pathname + location.search + "#dialog");
  }, []);

  useEffect(() => {
    if (locHash.current > 1) {
      handleClick();
    } else {
      locHash.current = locHash.current + 1;
    }

  }, [location]);

  const handleStepChange = (step) => {
    setActiveStep(step);
    setSelectedItem(images[step]);
  };

  const handleArrowsClicks = (e) => {
    if ("code" in e) {
      if (e.code === "ArrowRight"){
        handleNext();
      } else if  (e.code === "ArrowLeft") {
        handleBack();
      }
    }
  };

  return (
    <Dialog
      sx={{ maxWidth: "100%", maxHeight: "100%" }}
      open={Boolean(selectedItem)}
      onClose={handleClick}
      onKeyDown={handleArrowsClicks}
      maxWidth='lg'
      PaperProps={{
        style: {
          margin: 5
        }
      }}
    > 
      <Box
        sx={{
          bgcolor: 'background.paper',
          border: '2px solid #F3F5F9',
          boxShadow: 14,
          pt: 0.5,
          px: 0.5,
          pb: 0.5,
          maxWidth: "100%",
          maxHeight: "calc(100vh - 32px)",
        }}
      >
        <Box sx={{ textAlign: "center", display: { xs: 'block', sm: 'none' } }}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            animateHeight
          >
            {images.map((step, index) => (
              <Box>
                {("is_fb" in step) ? (
                  <Box
                    component="iframe"
                    alt="Enlarged photo"
                    sx={{
                      position: "block",
                      maxWidth: "100%",
                      maxHeight: "calc(100vh - 200px)",
                      overflow: 'hidden',
                    }}
                    key={step.id}
                    src={step.img_url}
                    sandbox=""
                  />
                ) : (
                    <Box
                      component="img"
                      alt="Enlarged photo"
                      sx={{
                        position: "block",
                        maxWidth: "100%",
                        maxHeight: "calc(100vh - 200px)",
                        overflow: 'hidden',
                      }}
                      key={step.id}
                      src={step.img_url}
                    />
                )}
              </Box>

            ))}

          </SwipeableViews>
        </Box>
        <Box sx={{ textAlign: "center", display: { xs: 'none', sm: 'block' } }}>
          {("is_fb" in selectedItem) ? (
            <Box
              component="iframe"
              alt="Enlarged photo"
              sx={{
                position: "block",
                width: "750px",
                height: "450px",
              }}
              src={selectedItem.img_url}
              sandbox="allow-popups allow-popups-to-escape-sandbox"
            />
          ) : (
              <Box
                component="img"
                alt="Enlarged photo"
                sx={{
                  position: "block",
                  maxWidth: "100%",
                  maxHeight: "calc(100vh - 200px)",
                  content: {
                    xs: `url(${selectedItem.img_url})`,
                    md: `url(${("large_img_url" in selectedItem) ? selectedItem.large_img_url : selectedItem.img_url})`,
                  }
                }}
            // src={selectedItem.img_url}
              />
          )}

        </Box>
        <Box>
            {
            ("flickr_url" in selectedItem) ? <FlickrBlock selectedItem={selectedItem} /> :
              ("is_fb" in selectedItem) ? <FBBlock selectedItem={selectedItem} /> :
                <Box><Typography sx={{ fontSize: 12 }}>{selectedItem.author}</Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box component="div" sx={{ whiteSpace: 'normal', display: 'flex', flexGrow: 1 }}>
                <Typography variant="caption" sx={{ display: "inline-block" , whiteSpace: "pre-line"  }}> 
                  For full resolution photos please contact us at myprecious@evpicz.com 
                </Typography>
              </Box>
                {(selectedItem.author === "@evpicz") && <Box py={1} sx={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: 'right'
                  }}>
                    <Box px={1} color="green">Free</Box>
                    <Box px={1} color="red"><del>£1.99</del></Box>
                  </Box>}
              </Box>
            </Box>
            }
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>      
    </Dialog>

  )
}

export default ModalEx;