import { useState, useEffect } from 'react';
import { galleryFirestore } from '../firebase/config';
import { collection, getDocs} from "firebase/firestore";

const useFirestore = (collectionPath) => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        const collectionRef = collection(galleryFirestore, collectionPath)
        // const q = query(collectionRef, where("author", "==", "@flickr_user1"), limit(10));
          
        getDocs(collectionRef).then((snapshot) => {
            let documents = []
            snapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                documents.push({...doc.data(), id: doc.id});
            });
            setDocs(documents);
          })
          .catch(err => {
              console.log(err.message)
          });

        
    }, [collectionPath]);
    return { docs };
}

export default useFirestore;