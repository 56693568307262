import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import NavBar from './components/NavBar';
import GalleryPage from "./pages/GalleryPage";
import SelectEventPage from "./pages/SelectEventPage";
import Footer from './components/Footer';
import SignUpPage from './pages/SignUpPage';
import AccountPage from './pages/AccountPage';

import AuthContextProvider from './contexts/AuthContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#4e4e4e',
    },
    error: {
      main: '#ff4a4a',
    }
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          paddingTop: 3,
          paddingBottom: 3,
        }
      }
    }
  },
})

export const App = () => {

  return (
    // <div className="App" onContextMenu={(e) => e.preventDefault()}>
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
            <AuthContextProvider>
              <NavBar />
              <Routes>
                <Route path="/event/:eventId/*" element={< GalleryPage />} />
                <Route path="/signup" element={< SignUpPage />} />
                <Route path="/account" element={< AccountPage />} />
                <Route path="/" element={< SelectEventPage />} />
              </Routes>
            </AuthContextProvider>
          <Footer />
        </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
  }

export default App;
