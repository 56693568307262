import { useState, useEffect } from 'react';
import { galleryFirestore } from '../firebase/config';
import { doc, getDoc} from "firebase/firestore";

const useFirestoreDoc = (docCollection, docId) => {
    const [singleDoc, setSingleDoc] = useState(null);

    useEffect(() => {

        if (docId) {
            const docRef = doc(galleryFirestore, docCollection, docId)
          
            getDoc(docRef).then((snapshot) => {
                if (snapshot.exists()) {
                    setSingleDoc({ ...snapshot.data(), id: snapshot.id })
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
                .catch(err => {
                    console.log(err.message)
                });
        }

        
    }, [docId]);
    return { singleDoc };
}

export default useFirestoreDoc;