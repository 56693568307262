import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useFirestore from "../hooks/useFirestore";
import SearchForm from "../components/SearchForm";

import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/config"

import { TextField, Autocomplete, Button, Box, Typography } from '@mui/material';

const SelectEventPage = () => {
  const { docs } = useFirestore('/events');
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(null);

  return (
    <Box pt={4} pb={4} px={{ xs: 0, sm: 5, md: 20, lg: 30, xl: 40 }} sx={{ maxWidth: 1024, margin: "auto" }}>
      <Typography variant="h6">
        Select your event
      </Typography>

      <Autocomplete
        options={docs.sort((a, b) => a.event_name.localeCompare(b.event_name))}
        getOptionLabel={(option) => option.event_name + ', ' + option.event_date.toDate().toDateString()}
        id="auto-highlight"
        autoHighlight
        renderInput={(params) => (
          <TextField {...params} label="Sport Event" variant="standard" />
        )}
        onChange={(event, newValue) => {
          logEvent(analytics, "select_content", {
            content_type: "event_select",
            item_id: newValue.id,
          });
          setInputValue(newValue);
        }}
      />
      {inputValue && <SearchForm eventDoc={inputValue} />}
      {inputValue && <Box pt={2}>
        <Button onClick={() => {
          logEvent(analytics, "select_content", {
            content_type: "event_click_all",
            item_id: inputValue.id,
          });
          navigate("/event/" + inputValue.id);
        }} variant="outlined">Browse All Photos</Button>
      </Box>}
    </Box>
  );
}

export default SelectEventPage;

