import { Box, Link, Typography, Avatar } from "@mui/material";

const licences = {
    "0": { "name": "All Rights Reserved", "url": "" },
    "1": { "name": "Attribution-NonCommercial-ShareAlike License", "url": "https://creativecommons.org/licenses/by-nc-sa/2.0/" },
    "2": { "name": "Attribution-NonCommercial License", "url": "https://creativecommons.org/licenses/by-nc/2.0/" },
    "3": { "name": "Attribution-NonCommercial-NoDerivs License", "url": "https://creativecommons.org/licenses/by-nc-nd/2.0/" },
    "4": { "name": "Attribution License", "url": "https://creativecommons.org/licenses/by/2.0/" },
    "5": { "name": "Attribution-ShareAlike License", "url": "https://creativecommons.org/licenses/by-sa/2.0/" },
    "6": { "name": "Attribution-NoDerivs License", "url": "https://creativecommons.org/licenses/by-nd/2.0/" },
    "7": { "name": "No known copyright restrictions", "url": "https://www.flickr.com/commons/usage/" },
    "8": { "name": "United States Government Work", "url": "http://www.usa.gov/copyright.shtml" },
    "9": { "name": "Public Domain Dedication (CC0)", "url": "https://creativecommons.org/publicdomain/zero/1.0/" },
    "10": { "name": "Public Domain Mark", "url": "https://creativecommons.org/publicdomain/mark/1.0/" },
}


const FlickrBlock = ({ selectedItem }) => {

    return (
        <Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box component="img" src="/flckr_logo.png" alt="/flckr_logo.png" sx={{ width: "30px", height: "10px" }} px={1} />
                        <Avatar alt={selectedItem.flickr_author} src={selectedItem.flickr_icon} sx={{ width: 24, height: 24 }} />
                        <Link href={selectedItem.flickr_profile} underline="hover" target="_blank" px={1}>
                            <Typography sx={{ fontSize: 12 }}>{selectedItem.flickr_author}</Typography>
                        </Link>

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box component="img" src="/flckr_logo.png" alt="/flckr_logo.png" sx={{ width: "30px", height: "10px" }} px={1} />
                        <Link href={selectedItem.flickr_url} underline="hover" target="_blank">
                            <Typography sx={{ fontSize: 12 }}>Original photo page</Typography>
                        </Link>
                    </Box>

                </Box>
                {('flickr_license' in selectedItem) && <Box sx={{ maxWidth: "20%" }}>
                    <Link href={licences[selectedItem.flickr_license]["url"]} underline="hover" target="_blank" >
                        <Typography sx={{ fontSize: 8 }}>{licences[selectedItem.flickr_license]["name"]}</Typography>
                    </Link>
                </Box>}

            </Box>


        </Box >
    );
}

export default FlickrBlock; 