
// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCsIvpzvL82eNzidr2Ac8BcF_YJNooxOM",
  authDomain: "evphotos-425db.firebaseapp.com",
  projectId: "evphotos-425db",
  storageBucket: "evphotos-425db.appspot.com",
  messagingSenderId: "695916679459",
  appId: "1:695916679459:web:56fa807616c8231c8a3c96",
  measurementId: "G-20ZMB9BBNE"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const galleryStorage = getStorage(app);
const galleryFirestore = getFirestore(app);
const galleryAuth = getAuth(app)

export { galleryStorage, galleryFirestore, galleryAuth, analytics };

