import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  TextField,
  Button,
  IconButton,
  Slider,
  Box,
  Typography
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/config";

import UploadPhotos from "./UploadPhotos";

function timeStringFromSeconds(value) {
  let vdate = new Date(value * 1000);
  return (
    vdate.toISOString().slice(0, 10) +
    " " +
    vdate.toLocaleTimeString().slice(0, 8)
  );
}

const SearchForm = ({ eventDoc }) => {
  const navigate = useNavigate();
  const [timeValue, setTimeValue] = useState([0, 0]);
  const [searchParams, setSearchParams] = useSearchParams();
  let starttime = searchParams.get("starttime");
  let endtime = searchParams.get("endtime");
  let bibnumber = searchParams.get("bibnumber");

  const [bibNumber, setBibNumber] = useState(bibnumber);
  const [timeSearchToggle, setTimeSearchToggle] = useState(
    starttime &&
      endtime &&
      !isNaN(parseInt(starttime, 10)) &&
      !isNaN(parseInt(endtime, 10))
  );

  useEffect(() => {
    if (eventDoc) {
      if (("photos_time_start" in eventDoc) & ("photos_time_end" in eventDoc)) {
        setTimeValue([
          starttime
            ? Math.floor(starttime / 1000)
            : eventDoc.photos_time_start.seconds,
          endtime
            ? Math.floor(endtime / 1000)
            : eventDoc.photos_time_end.seconds,
        ]);
      }
    }
  }, [timeSearchToggle, eventDoc, starttime, endtime]);

  const handleSearchClick = () => {
    let searchParamsVals = {};
    if (bibNumber) {
      searchParamsVals = { ...searchParamsVals, bibnumber: bibNumber };
    }
    if (timeSearchToggle) {
      searchParamsVals = {
        ...searchParamsVals,
        ...{ starttime: timeValue[0] * 1000, endtime: timeValue[1] * 1000 },
      };
    }

    let searchString = Object.entries(searchParamsVals).reduce(
      (acc, [k, v]) => acc + "&" + k + "=" + v,
      ""
    );
    if (searchString.length > 0) {
      searchString = "?" + searchString.substring(1);
    }
    if (eventDoc) {
      logEvent(analytics, "select_content", {
        content_type: "event_click_search",
        item_id: eventDoc.id,
      });
      navigate("/event/" + eventDoc.id + searchString);
    } else {
      setSearchParams(searchParamsVals);
    }
    // if (bibNumber) {
    //     (eventDoc ? navigate("/event/" + eventDoc.id + "?bibnumber=" + bibNumber) : setSearchParams({ bibnumber: bibNumber }))
    // } else {
    //     (eventDoc ? navigate("/event/" + eventDoc.id) : setSearchParams({}))
    // }
  };



  return (
    <div className="search-form">
      <h4>Search your photos</h4>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Bib Number"
            variant="standard"
            onChange={(evt) => {
              setBibNumber(evt.target.value);
            }}
            defaultValue={bibnumber}
            sx={{ width: 150 }}
          />
          <Button onClick={handleSearchClick} variant="outlined">
            Search your photos
          </Button>
          {eventDoc &&
            "photos_time_start" in eventDoc &&
            "photos_time_end" in eventDoc && (
              <IconButton
                aria-label="more"
                onClick={() => setTimeSearchToggle(!timeSearchToggle)}
              >
                {!timeSearchToggle ? <UpdateIcon /> : <UpdateDisabledIcon />}
              </IconButton>
            )}
        </Box>
        {eventDoc && eventDoc.allowPhotosUpload && <UploadPhotos eventId={eventDoc.id}/>}
      </Box>
      {timeSearchToggle && eventDoc && (
        <Box px={4}>
          <Slider
            valueLabelDisplay="auto"
            value={timeValue}
            min={eventDoc.photos_time_start.seconds}
            step={1}
            max={eventDoc.photos_time_end.seconds}
            onChange={(_, newValue) => setTimeValue(newValue)}
            valueLabelFormat={timeStringFromSeconds}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -1,
            }}
          >
            <Box>
              <Typography
                fontSize="0.75rem"
                fontWeight="500"
                letterSpacing="0.2"
              >
                {new Date(timeValue[0] * 1000).toISOString().slice(0, 10)}
              </Typography>
              <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours", "minutes", "seconds"]}
                inputFormat="HH:mm:ss"
                mask="__:__:__"
                value={new Date(timeValue[0] * 1000)}
                onChange={(newValue) => {
                  if (newValue) {
                    if (newValue.toString() !== "Invalid Date") {
                      setTimeValue([
                        Math.floor(newValue.getTime() / 1000),
                        timeValue[1],
                      ]);
                    }
                  }
                }}
                onAccept={(newValue) => {
                  setTimeValue([
                    Math.floor(newValue.getTime() / 1000),
                    timeValue[1],
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    sx={{ width: 110 }}
                    size="small"
                    {...params}
                  />
                )}
                minTime={new Date(eventDoc.photos_time_start.seconds * 1000)}
                maxTime={new Date(timeValue[1] * 1000)}
                disableIgnoringDatePartForTimeValidation={true}
              />
            </Box>
            <Box>
              <Typography
                fontSize="0.75rem"
                fontWeight="500"
                letterSpacing="0.2"
              >
                {new Date(timeValue[1] * 1000).toISOString().slice(0, 10)}
              </Typography>
              <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours", "minutes", "seconds"]}
                inputFormat="HH:mm:ss"
                mask="__:__:__"
                value={new Date(timeValue[1] * 1000)}
                onChange={(newValue) => {
                  if (newValue) {
                    if (newValue.toString() !== "Invalid Date") {
                      setTimeValue([
                        timeValue[0],
                        Math.floor(newValue.getTime() / 1000),
                      ]);
                    }
                  }
                }}
                onAccept={(newValue) => {
                  setTimeValue([
                    timeValue[0],
                    Math.floor(newValue.getTime() / 1000),
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    sx={{ width: 100 }}
                    size="small"
                    {...params}
                  />
                )}
                maxTime={eventDoc.photos_time_end.toDate()}
                minTime={new Date(timeValue[0] * 1000)}
                disableIgnoringDatePartForTimeValidation={true}
              />
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default SearchForm;