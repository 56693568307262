import { useState } from "react";
import { useParams } from 'react-router-dom'

import { Box } from "@mui/system";

import EventDetails from "../components/EventDetails";
import ImageGrid from "../components/ImageGrid";
import ModalEx from "../components/Modal";
import SearchForm from "../components/SearchForm";

import useFirestoreDoc from "../hooks/useFirestoreDoc";

const GalleryPage = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [images, setImages] = useState([]);
    const { eventId } = useParams();
    const { singleDoc } = useFirestoreDoc("events", eventId);

    return ( 
        <Box sx={{maxWidth:1280, margin: "auto"}}>
            <EventDetails singleDoc={singleDoc} />
            <SearchForm eventDoc={singleDoc} />
            <ImageGrid setSelectedItem={setSelectedItem} eventId={eventId} images={images} setImages={setImages} />
            { selectedItem && (
                <ModalEx selectedItem={selectedItem} setSelectedItem={setSelectedItem} images={images} />
            )}
      </Box>
     );
}
 
export default GalleryPage;