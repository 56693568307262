import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { collection, query, getDocs, limit, orderBy, startAfter, where, Timestamp } from "firebase/firestore";
import Button from '@mui/material/Button';
import { Box, Grid, Typography } from "@mui/material";

import ImageItem from "./ImageItem";
import { galleryFirestore } from '../firebase/config';

const limitLoading = 12;

const ImageGrid = ({ setSelectedItem, eventId, images, setImages }) => {
    const [loadFromDoc, setLoadFromDoc] = useState(null);
    const [lastDoc, setLastDoc] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [searchParamsHook, setSearchParamsHook] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setLoadFromDoc(null);
        setImages([]);
        setHasMore(true);
        setSearchParamsHook(searchParams);

    }, [searchParams])

    useEffect(() => {
        setIsLoading(true)
        const collectionRef = collection(galleryFirestore, "/events/" + eventId + "/images");

        let qConditions = [];
        let bibNumber = searchParams.get("bibnumber");
        qConditions = bibNumber ? [...qConditions, where("bib_numbers", "array-contains", bibNumber)] : qConditions;

        let startTime = searchParams.get("starttime");
        // startTime = startTime ? new Date(startTime) : startTime;
        // qConditions = startTime && startTime instanceof Date && !isNaN(startTime) ? [...qConditions, where("date_taken", ">=", Timestamp.fromDate(startTime))] : qConditions;
        qConditions = startTime && !isNaN(parseInt(startTime, 10)) ? [...qConditions, where("date_taken", ">=", Timestamp.fromMillis(parseInt(startTime, 10)))] : qConditions;


        let endTime = searchParams.get("endtime");
        // endTime = endTime ? new Date(endTime) : endTime;
        // qConditions = endTime && endTime instanceof Date && !isNaN(endTime) ? [...qConditions, where("date_taken", "<=", Timestamp.fromDate(endTime))] : qConditions;
        qConditions = endTime && !isNaN(parseInt(endTime, 10)) ? [...qConditions, where("date_taken", "<=", Timestamp.fromMillis(parseInt(endTime, 10)))] : qConditions;

        let starterDoc = loadFromDoc ? [startAfter(loadFromDoc)] : [];
        let q = query(collectionRef, ...qConditions, orderBy("date_taken", "desc"), limit(limitLoading), ...starterDoc);


        getDocs(q).then((snapshot) => {
            let documents = [];
            let lastDocTemp = null;
            snapshot.forEach((doc) => {
                documents.push({ ...doc.data(), id: doc.id, isSelected: false });
                lastDocTemp = doc;
            });

            if (documents.length < limitLoading) {
                setHasMore(false);
            }

            setImages([...images, ...documents]);
            setLastDoc(lastDocTemp);
        })
        .catch(err => {
            console.log(err.message)
        });
        setIsLoading(false)

    }, [loadFromDoc, searchParamsHook]);

    return (
    <div className="photo-results">
        <h4>Event photos:</h4>
        <Box pb={3}>
            {
            images && (images.length > 0) ?
                    <Grid container justify="space-evenly" spacing={0.5}>
                        {images.map(doc => (
                
                    <Grid item xs={12} sm={6} md={3} key={doc.id} 

                        onClick={() => setSelectedItem(doc)}
                    >
                        <ImageItem doc={doc} />
                    </Grid>
                
            ))}</Grid>:
                    <Box pt={5} pb={10} textAlign="center">
                        <Typography>
                            Sorry, nothing found. Please check later or select another event.
                        </Typography>
 
                    </Box>  
                
            }
            <Box textAlign="center" pt={2}>
                {hasMore && <Button 
                    onClick={() => {
                        setLoadFromDoc(lastDoc);
                    }}
                    disabled={isLoading}
                    variant='outlined'
                >
                    {isLoading ? "loading more..." : "Load More"}
                </Button>}
            </Box>
        </Box>
    </div>

    )
}

export default ImageGrid;
