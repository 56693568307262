import { Paper, Button, Box, TextField, Typography, Grid, Link } from '@mui/material'
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { galleryAuth } from '../firebase/config';

const SignInForm = ({ setOpenSignInForm }) => {

    const [emailError, setEmailError] = useState(false);
    const [authError, setAuthError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        signInWithEmailAndPassword(galleryAuth, data.get('email'), data.get('password'))
            .then((cred) => {
                setOpenSignInForm(false);
                setAuthError('')
            })
            .catch((err) => {
                setAuthError(err.message.replace('Firebase: ', ''))
            })
    };

    const validateEmail = (evt) => {
        evt.preventDefault();
        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(evt.target.value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }


    return (
        <Box sx={{
            display: "block",
            maxWidth: 500,
            marginX: "auto",

        }}>
            <Paper>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, px: "10%" }}>
                    <Box>
                        <Typography variant='h6' textAlign="center">Sign In</Typography>
                        <Typography textAlign="center">Log in with email & password</Typography>
                    </Box>
                    <Grid container spacing={2} pt={5}>
                        <Grid item xs={12}>
                            <TextField
                                error={emailError}
                                helperText={emailError && "Invalid email format"}
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onBlur={validateEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    {authError && <Typography color="error">{authError}</Typography>}
                    <Grid container sx={{ pb: 2 }}>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
}

export default SignInForm;