import { Paper, Button, Box, TextField, Typography, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

import { galleryFirestore } from '../firebase/config';
import { getAuth, updateProfile } from "firebase/auth";

import { AuthContext } from '../contexts/AuthContext';

const defaulDict = { firstname: '', lastname: '', allowExtraEmails: false, email: '' }

const AccountForm = () => {
    const [isInformationUpdated, setIsInformationUpdated] = useState(false);
    const [isNewInformation, setIsNewInformation] = useState(false);
    const [accountData, setAccountData] = useState(defaulDict);
    const [rst, setRst] = useState(null);

    const { userId, userObj } = useContext(AuthContext);

    const [newDisplayName, setNewDisplayName] = useState(userObj && userObj.displayName ? userObj.displayName: '');
    const [displayNameValidate, setDisplayNameValidate] = useState(false);

    useEffect(() => {
        if (userObj){
            userObj.displayName && setNewDisplayName(userObj.displayName);
        }
    }, [userObj]);

    useEffect(() => {

        if (userId) {
            const docRef = doc(galleryFirestore, "users", userId)

            getDoc(docRef).then((snapshot) => {
                if (snapshot.exists()) {
                    setAccountData({ ...snapshot.data(), id: snapshot.id })
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
                .catch(err => {
                    console.log(err.message)
                });
        }
    }, [userId, isInformationUpdated, rst]);

    const setInputValues = (e, field) => {
        e.preventDefault();
        setIsNewInformation(true);
        setAccountData({ ...accountData, [field]: e.target.value });
    };

    const setInputCheked = (e, field) => {
        e.preventDefault();
        setIsNewInformation(true);
        setAccountData({ ...accountData, [field]: e.target.checked });
    };

    const handleReset = (e) => {
        setIsNewInformation(false);
        setRst(e);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setDoc(doc(collection(galleryFirestore, "users"), userId), {
            firstname: accountData.firstname,
            lastname: accountData.lastname,
            allowExtraEmails: Boolean(accountData.allowExtraEmails),
        }).then(() => {
            setIsInformationUpdated(true);
            setIsNewInformation(false);
        })

    }

    const handleDisplayNameChange = (e) => {
        e.preventDefault(); 
        if (!(/^([a-zA-Z\-0-9 @]*)$/).test(e.target.value)){
            setDisplayNameValidate(true);
        } else {
            setDisplayNameValidate(false);
            setNewDisplayName(e.target.value);
        }   
    }

    const handleUpdateDisplayName = (e) => {
        e.preventDefault();
        updateProfile(userObj, {displayName: newDisplayName}).then(() => {
            setNewDisplayName(userObj.displayName? userObj.displayName: '');
          }).catch((error) => {
            console.log(error);
          });
    }

    return (
        <Box sx={{
            display: "block",
            maxWidth: 500,
            marginX: "auto",
            py: 5
        }}>
            <Paper>
                {!userId && (<Typography textAlign="center">Please sign in with your details</Typography>)}
                {userId && <Box component="form" onSubmit={handleSubmit} onReset={handleReset} sx={{ mt: 3, px: "10%" }}>
                    {<Box>
                        <Typography textAlign="center">Please check and update your information</Typography>
                    </Box>}
                    <Grid container spacing={2} pt={5} px={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                value={accountData.firstname}
                                onChange={(e) => { setInputValues(e, "firstname") }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                value={accountData.lastname}
                                onChange={(e) => { setInputValues(e, "lastname") }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" name="allowExtraEmails" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                                checked={accountData.allowExtraEmails}
                                onChange={(e) => { setInputCheked(e, "allowExtraEmails") }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                disabled={!isNewInformation}
                                type="reset"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Cancel
                            </Button>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                disabled={!isNewInformation}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                }
            </Paper>
            <Paper>
                <Box sx={{ mt: 3, px: "10%" }}>
                {<Box>
                        <Typography textAlign="center">Account information</Typography>
                </Box>}
                <Grid container spacing={2} pt={5} px={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={displayNameValidate}
                            helperText={displayNameValidate && "Only letters, numbers, @ and space are allowed"}
                            fullWidth
                            id="dispName"
                            label="Display Name"
                            name="dispName"
                            value={newDisplayName}
                            onChange={handleDisplayNameChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                            <Button
                                disabled={(newDisplayName === ((userObj && userObj.displayName)? userObj.displayName: ''))}
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={handleUpdateDisplayName}
                            >
                                Update
                            </Button>
                        </Grid>
                    <Grid item xs={12} mb={2}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            disabled
                            value={userObj ? userObj.email : ''}
                        />
                    </Grid>
                </Grid>
                </Box>
            </Paper>
        </Box>
    )
};


export default AccountForm;