import { createContext, useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';


import { galleryAuth } from '../firebase/config';
import { galleryFirestore } from '../firebase/config';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userObj, setUserObj] = useState(null);
    const [userObjEx, setUserObjEx] = useState(null);
    const [userId, setUserId] = useState(() => {
        const localUserId = localStorage.getItem('user_auth');
        return (localUserId) ? JSON.parse(localUserId).uid : null;
    });

    const unsubAuth = onAuthStateChanged(galleryAuth, (user) => {
        if (user) {
            if ((user.uid !== userId) & (!isLoggedIn)) { setUserId(user.uid); }
            if (!isLoggedIn) { setIsLoggedIn(true); }
            setUserObj(user);
        } else {
            if (isLoggedIn) { setIsLoggedIn(false); }
            setUserObj(null);
        }
    })

    useEffect(() => {
        if (userId) {
            localStorage.setItem("user_auth", JSON.stringify({ "uid": userId }));
        } else {
            localStorage.removeItem("user_auth");
        }
    }, [userId])


    useEffect(() => {
        if (userId && (!userObjEx)) {
            const docRef = doc(galleryFirestore, "users", userId)
  
            getDoc(docRef).then((snapshot) => {
                if (snapshot.exists()) {
                    let snapData = snapshot.data();
                    setUserObjEx({ firstName: snapData.firstname, lastName: snapData.lastname });
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
                .catch(err => {
                    console.log(err.message)
                });
        }
    }, [userId]);


    const signOutAuth = () => {
        signOut(galleryAuth);
        setUserId(null);
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, userObj, userId, signOutAuth, userObjEx }}>
            {props.children}
        </AuthContext.Provider>

    );
}

export default AuthContextProvider;