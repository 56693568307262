import React, { useState, useContext } from "react";
import { Button, Box, Typography, Popover, Dialog, Alert, Snackbar } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

import { AuthContext } from '../contexts/AuthContext';
import SignInForm from "./SignInForm";
import UploadPhotosProgress from "./UploadPhotosProgress";

const UploadPhotos = ({eventId}) => {
  const { isLoggedIn } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const [files, setFiles] = useState(null);
  const [error, setError] = useState(null);
  const [openSignInForm, setOpenSignInForm] = useState(false);

  const types = ['image/png', 'image/jpeg'];

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  const handleChange = (e) => {
    let selected = e.target.files;

    let isAllImages = [...selected].reduce((allImages, e) => allImages + 1 - types.includes(e.type) ,0);

    if (selected && (selected.length > 0) && (isAllImages === 0)) {
      setFiles(selected);
      setError(null);
    } else {
      setFiles(null);
      setError("Please select only image files (png or jpg)");
    }

  };

  const openSiginDialog = () => {
    if (!isLoggedIn & !openSignInForm){
      setOpenSignInForm(true);
    }
  }

  const handleSnackBarClose = () => {
    setError(null);
  }

  const renderSignIn = (
    <Dialog
        open={openSignInForm}
        onClose={() => setOpenSignInForm(false)}
    >
        <SignInForm setOpenSignInForm={setOpenSignInForm} />
    </Dialog>
);

  const filesSnackbarAction = (
    files && <UploadPhotosProgress fls={[...files]} setFiles={setFiles} eventId={eventId}/>
  )

  return (
    <Box sx={{ maxWidth: "20%" }}>
      <Button
        variant="contained"
        size="small"
        component="label"
        startIcon={<UploadIcon />}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={openSiginDialog}
        disabled={Boolean(files)}
      >
        Upload
        {isLoggedIn && <input
          hidden
          accept="image/*"
          multiple
          type="file"
          onChange={handleChange}
        />}
      </Button>
      <Snackbar 
        open={Boolean(error)} 
        autoHideDuration={6000} 
        onClose={handleSnackBarClose} 
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
      >
        <Alert severity="error" onClose={handleSnackBarClose}>{error}</Alert>
      </Snackbar>
      {files && <Snackbar 
        open={Boolean(files)} 
        // onClose={handleFilesSnackBarClose} 
        action={filesSnackbarAction}
        ContentProps={{ sx: {'& .MuiSnackbarContent-action': {m:0, width:'100%', p:0.5} }}}
      />}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>Upload more photos</Typography>
      </Popover>
      {renderSignIn}
    </Box>
  );
};

export default UploadPhotos;
