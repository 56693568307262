import { Box, Typography } from "@mui/material";

const ImageItem = ({ doc }) => {
    return ( 
        <Box sx={{border: '2px solid #F3F5F9'}}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "hidden",
                height: 0,
                padding: "50% 0"            
                }}>

                {("is_fb" in doc) && (
                    <Box
                        component="iframe"
                        sx={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            top: 0,
                            opacity: 1.0,
                            "&:hover": {
                                opacity: 0.8
                            },
                        }}
                        alt="Athlete photo"
                        src={doc.img_url}
                        sandbox="allow-popups allow-popups-to-escape-sandbox"
                    />
                )}

                {!("is_fb" in doc) && (
                    <Box
                        component="img"
                        sx={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            top: 0,
                            opacity: 1.0,
                            "&:hover": {
                                opacity: 0.8
                            },
                        }}
                        alt="Athlete photo"
                        src={doc.img_url}
                    />
                )}

                </Box>
            
            <Box
                pt={1} pb={2} px={1}
                sx={{ display: "flex", flexDirection: "row" }}
            >
                { ("author" in doc) && <Box
                    sx={{
                        alignItems: "flex-start",
                        color: "black",
                        flexGrow: 1
                    }}
                >
                    <Typography fontSize={10}>
                        {doc.author}
                    </Typography>
                </Box> }
                {
                    ("date_taken" in doc) && <Box
                    >
                        <Typography fontSize={10}>
                            {doc.date_taken.toDate().toISOString().slice(0, 10) + ' ' + doc.date_taken.toDate().toLocaleTimeString().slice(0, 8)}
                        </Typography>
                    </Box>
                }
            </Box>
        </Box>

     )
    ;
}
 

export default ImageItem;