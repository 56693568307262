import { Box, Link, Typography } from "@mui/material";

const FBBlock = ({ selectedItem }) => {

    return (
        <Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box component="img" src="/fb_logo.png" alt="/fb_logo.png" sx={{ width: "20px", height: "14px" }} px={1} />
                        <Typography sx={{ fontSize: 12 }}>{selectedItem.author}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box component="img" src="/fb_logo.png" alt="/fb_logo.png" sx={{ width: "20px", height: "14px" }} px={1} />
                        <Link href={selectedItem.img_url} underline="hover" target="_blank">
                            <Typography sx={{ fontSize: 12 }}>Original photo post page</Typography>
                        </Link>
                    </Box>

                </Box>
            </Box>


        </Box >
    );
}

export default FBBlock; 